import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSharedCouponDetails } from "../../../Services/CoupanServices";
import { MEDIA_BASE_URL } from "../../../Utils/Constants";
import logo from "../../../Assets/images/logo.png";
import { tostE } from "../../../Utils/Toast";
import moment from "moment";
import { getDateForMenu } from "../../../Utils/CommonFunctions";
import DocumentMeta from 'react-document-meta';
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

export default function CouponDetail() {
  const { id } = useParams(); // Retrieve the ID from URL params
  const [loading, setLoading] = useState(false); // State for loader
  const [refresh] = useState(false);
  const [couponDetails, setCouponDetails] = useState(null);

  const meta = {
    title: couponDetails?.title,
    description: couponDetails?.description,
    image: MEDIA_BASE_URL + couponDetails?.couponImage
  };

  useEffect(() => {
    setLoading(true); // Show loader before making API call
    const fetchCouponDetails = async () => {
      try {
        const response = await getSharedCouponDetails(id);
        if (response.status === 200) {
          setTimeout(() => {
            setLoading(false); // Hide loader after API call completes
            setCouponDetails(response.data.data); // Assuming response.data contains coupon details
          }, 1000);
        } else {
          // Handle error case
          setLoading(false); // Hide loader after API call completes
          tostE("Failed to fetch coupon details:", response.statusText);
        }
      } catch (error) {
        setLoading(false); // Hide loader after API call completes
        tostE("Error fetching coupon details:", error);
      }
    };

    fetchCouponDetails();
  }, [id, refresh]);

  const formatDate = (
    slotStartTime,
    duration,
  ) => {
    slotStartTime = moment.utc(slotStartTime , "HH:mm:ss").local().format('HH:mm:ss');
    if (!slotStartTime) {
      return '';
    }
    
    const [startHour, startMinute] = slotStartTime.split(':').map(Number);
    let startTotalMinutes = startHour * 60 + startMinute;
    let endTotalMinutes = startTotalMinutes + duration;
    let endHour = Math.floor(endTotalMinutes / 60) % 24;
    let endMinute = endTotalMinutes % 60;
    let formattedStartTime = formatTime(startHour, startMinute);
    let formattedEndTime = formatTime(endHour, endMinute);
  
    return `${formattedStartTime}-${formattedEndTime}`;
  };
  
  
  function formatTime(hour, minute) {
    let amPM = hour >= 12 ? 'PM' : 'AM';
    let formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    let formattedMinute = minute < 10 ? '0' + minute : minute;
    return `${formattedHour}:${formattedMinute}${amPM}`;
  }

  
  
  return (
    <>
      {loading && (
        <div className="preload">
          <img src={logo} alt="Insta-coupon Logo" className="preloadlogo" />
        </div>
      )}

      {!loading ? (
        <DocumentMeta {...meta}>
        <div className="coupon-sec-detail section-space">
          <div className="container">
            <div className="coupon-sec-detail-top-head mb-4 d-flex align-items-center justify-content-center ">
              <div className="coupan-sec-top-head-left">
                <div className="page-title ">
                <a href="https://instacoupon.com/download-our-app/" className="btn btn-primary">Download Our App !</a>
                </div>
              </div>
            </div>
            <div className="coupon-sec-detail-main">
              <div className="coupan-box-detail d-flex align-items-center mb-4">
                <figure>
                  <img src={MEDIA_BASE_URL + couponDetails?.couponImage} />
                </figure>
                <figcaption>
                  <h4>{couponDetails?.title}</h4>
                  <div className="date-box mb-2">
                    <i className="ri-calendar-line"></i>
                   {getDateForMenu(couponDetails?.availableDate ? couponDetails?.availableDate : couponDetails?.endDateTime)}{', '}
                   {formatDate(couponDetails?.slotStartTime, couponDetails?.duration, couponDetails?.durationText)}
                  </div>
                  <div className="price-box">
                  List Price :&nbsp;
                    <b className="price-text">${couponDetails?.price}</b>
                  </div>
                  <div className="price-box">
                   Category :&nbsp;
                    <b className="price-text">{couponDetails?.categoryName}</b>
                  </div>
                </figcaption>
              </div>

              <div className="coupon-sec-detail-content mb-4">
                <h4>Discounted Price</h4>
                <p>${couponDetails?.discountedPrice}</p>
              </div>    
              <div className="coupon-sec-detail-content mb-4">
                <h4>Discount Percentage</h4>
                <p>{couponDetails?.disPercentage}% off</p>
              </div>
              <div className="coupon-sec-detail-content mb-4 coupon-sec-des">
                  <h4>Number of Slots </h4>
                  <p>{couponDetails?.numberOfSlots}</p>
              </div>     
              <div className="mb-4">
                <FacebookShareButton url={`${window.location.origin}/common/shared-coupon/${id}`} hashtag='#instacoupon'>
                  <FacebookIcon size={32} round></FacebookIcon>
                </FacebookShareButton>
                &nbsp;
                <WhatsappShareButton url={`${window.location.origin}/common/shared-coupon/${id}`} title={`${couponDetails?.title}`}>
                  <WhatsappIcon size={32} round></WhatsappIcon>
                </WhatsappShareButton>
              </div>

              <div className="coupon-sec-detail-content mb-4 coupon-sec-des customdescription-box">
                <h4>Description</h4>
                <p>{couponDetails?.description}</p>
              </div>
              {couponDetails?.galleryImages.length ===0 ? '':
              <div className="coupon-sec-detail-content mb-4 coupon-se-gallery">
                <h4>Gallery</h4>
                <ul className="gallery-img-list d-flex">
                  {couponDetails?.galleryImages?.map((image, index) => (
                    <li key={index}>
                      <figure>
                        <img
                          key={index}
                          src={MEDIA_BASE_URL + image}
                          alt={`Image ${index + 1}`}
                        />
                      </figure>
                    </li>
                  ))}
                </ul>
              </div>
              }
            </div>
            <div className="coupon-sec-detail-top-head mb-4 d-flex align-items-center justify-content-center ">
              <div className="coupan-sec-top-head-left">
                <div className="page-title ">
                <a href="https://instacoupon.com/download-our-app/" className="btn btn-primary">Download Our App !</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        </DocumentMeta>
      ) : null}
    </>
  );
}
